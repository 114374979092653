import React, { ReactNode } from 'react';

import styles from './index.module.less';

interface MainStructureProps {
  componentLeft: ReactNode;
  componentRight: ReactNode
}

export const MainStructure: React.FC<MainStructureProps> = (props) => {
  const { componentLeft, componentRight } = props
  return (
    <div className={styles.container}>
      <div className={styles.left}>
        {componentLeft}
      </div>
      <div className={styles.right}>
        {componentRight}
      </div>
    </div>
  );
}
